<template>
  <div class="home">
    <img src="/images/subscribe.png" width=800 height=800 class="center">

    <div id="contact">
      <p> <form id="fs-frm" name="registration-form" accept-charset="utf-8" action="https://formspree.io/f/mayvylad" method="post" class="center">
        <fieldset id="fs-frm-inputs">
        <input type="email" name="_replyto" id="email-address" placeholder="email@address.com" required="">
        <input type="hidden" name="_subject" id="email-subject" value="Registration Form Submission">
        </fieldset>
        <input type="submit" value="Tune In" class="center">
      </form> </p>
    </div>
    <img src="/images/tune-in.png" width=800 height=800 class="center">

  </div>
</template>

<style>
</style>

<script>
  export default {
    data: function () {
      return {
      };
    },
    created: function () {},
    methods: {},
  };
</script>