<template>
  <div class="home">
    <img src="/images/landing-page.png" width=800 height=800 class="center">
    <a href="dashboard"> <button class="center"> <b>Click Here</b></button></a>
  </div>
</template>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height:100%;
}
</style>

<script>
  export default {
    data: function () {
      return {    
      };
    },
    created: function () {},
    methods: {},
  };
</script>